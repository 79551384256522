/**
 * Current order state management via React Context.
 */

import { v4 as uuidv4 } from 'uuid';

import { Address, Delivery, DeliveryItem, DeliveryStatus, DeliveryType, Relationship, User } from './types';

const ANONYMOUS_USER_ID = '_anonymous';

// Can expand this check but not really needed.
export const isValidDelivery = (delivery: Delivery): boolean => {
  if (typeof delivery.id == 'undefined') {
    return false;
  }
  if (typeof delivery.type == 'undefined') {
    return false;
  }
  if (typeof delivery.relationshipId == 'undefined') {
    return false;
  }
  return true;
};

/**
 * Whether the delivery has a real user ID for the initiator.
 */
export const hasInitiator = (delivery: Delivery): boolean => {
  if (!delivery.initiatorId || delivery.initiatorId === ANONYMOUS_USER_ID) {
    return false;
  }

  return true;
};

/**
 * Whether we've attached a complete address object locally as the initiator.
 */
export const hasInitiatorAddress = (delivery: Delivery): boolean => {
  if (delivery.type === DeliveryType.request) {
    return !!delivery.dropoffAddress;
  }

  // Must be send.
  return !!delivery.pickupAddress;
};

/**
 * Whether a delivery has a valid pickup time. This performs an additional check beyond
 * an undefined field, since the API may have set a default date/time.
 */
export const hasValidPickupTime = (delivery: Delivery): boolean => {
  const { pickupTime } = delivery;
  if (!pickupTime) {
    return false;
  }
  if (new Date(pickupTime).getFullYear() < 2021) {
    return false;
  }

  return true;
};

/**
 * Whether a delivery has a valid dropoff time. This performs an additional check beyond
 * an undefined field, since the API may have set a default date/time.
 */
export const hasValidDropoffTime = (delivery: Delivery): boolean => {
  const { dropoffTime } = delivery;
  if (!dropoffTime) {
    return false;
  }
  if (new Date(dropoffTime).getFullYear() < 2021) {
    return false;
  }

  return true;
};

/**
 * The route to which we should send an initiator who is finalizing an order.
 * This looks at which properties are present and makes a best guess.
 */
export const checkoutRouteForDelivery = (delivery: Delivery): string => {
  if (!hasInitiator(delivery)) {
    // Need user account.
    return '/order/account';
  }
  if (!hasInitiatorAddress(delivery)) {
    // Need address.
    return '/order/location';
  }
  if (delivery.type === DeliveryType.send && !hasValidPickupTime(delivery)) {
    // Need pickup time.
    return '/order/timing';
  }
  if (!delivery.ex || !delivery.initiator) {
    // Need ex information.
    return '/order/ex';
  }

  // Have everything, move to confirm.
  return '/order/confirm';
};

/**
 * Whether a delivery is still active. (i.e. not cancelled or completed.)
 */
export const isInProgress = (delivery: Delivery): boolean => {
  return ![DeliveryStatus.completed, DeliveryStatus.cancelled].includes(delivery.status);
};

/**
 * Whether a delivery has been completed.
 */
export const isCompleted = (delivery: Delivery): boolean => delivery.status === DeliveryStatus.completed;

/**
 * Whether a delivery has been cancelled for some reason.
 */
export const isCancelled = (delivery: Delivery): boolean => delivery.status === DeliveryStatus.cancelled;

/**
 * Fetches the initiator's name from a delivery if present.
 */
export const getInitiatorName = (delivery: Delivery): string => {
  if (delivery.initiator) {
    return delivery.initiator.name;
  }

  return '';
};

/**
 * Fetches the ex's name from a delivery if present.
 */
export const getExName = (delivery: Delivery): string => {
  if (delivery.ex) {
    return delivery.ex.name || '';
  }

  return '';
};

/**
 * Fetches the ex's phone number from a delivery if present.
 */
export const getExPhoneNumber = (delivery: Delivery): string => {
  if (delivery.ex) {
    return delivery.ex.phone || '';
  }

  return '';
};

/**
 * Fetches the name of the other party in the delivery.
 */
export const getOtherPartyName = (delivery: Delivery, user: User): string => {
  if (user.id === delivery.initiatorId) {
    // We're the initiator, return the ex's name.
    return getExName(delivery);
  }

  // We're the ex, get the initiator.
  return getInitiatorName(delivery);
};

/**
 * Returns the fulfillment method from the perspective of the given user.
 */
export const getFulfillmentType = (delivery: Delivery, user: User): string => {
  if (user.id === delivery.dropoffUserId) {
    // We were the dropoff, received items.
    return 'Received';
  }

  // We were the pickup, sent the items.
  return 'Sent';
};

/**
 * Formats the names of the available items from a delivery into a readable string.
 */
export const availableItemNames = (items: DeliveryItem[]): string => {
  return items
    .filter((item) => item.available)
    .map((item) => item.name)
    .join(', ');
};

/**
 * DELIVERY STATE MANAGEMENT
 */

/**
 * Determines where to navigate to based on user and delivery.
 */

// Possible delivery steps for the initiator.
export enum DeliveryStep {
  'index',
  'feed',
  'cart',
  'account',
  'confirm',
  'ex',
  'status',
}

// Possible delivery steps for the ex.
export enum ExStep {
  'index',
  'items',
  'address',
  'timing',
  'confirm',
  'status',
}

/**
 * Configures a new delivery instance for the given user and type.
 */
export const createDelivery = (userId: string | undefined, type: DeliveryType = DeliveryType.request): Delivery => {
  const delivery: Delivery = {
    id: uuidv4(),
    type,
    status: DeliveryStatus.draft,
    initiatorId: userId || ANONYMOUS_USER_ID,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };

  if (type === DeliveryType.request) {
    delivery.dropoffUserId = userId;
  } else {
    delivery.pickupUserId = userId;
  }

  return delivery;
};

/**
 * Updates the type of a delivery and returns a new copy.
 * This will also reset address information associated with the delivery.
 */
export const setType = (delivery: Delivery, type: DeliveryType): Delivery => ({
  ...delivery,
  pickupAddress: undefined,
  pickupAddressId: undefined,
  pickupTime: undefined,
  dropoffAddress: undefined,
  dropoffAddressId: undefined,
  type,
});

/**
 * Updates a delivery object with the provided initiating user ID.
 */
export const addInitiator = (delivery: Delivery, userId: string): Delivery => ({
  ...delivery,
  initiatorId: userId,
});

/**
 * Updates a delivery object based on the new initiator address result.
 * This returns a new object, not a mutation of the original.
 */
export const addAddress = (delivery: Delivery, address: Address): Delivery => {
  if (delivery.type === DeliveryType.request) {
    // It's a retrieve request, so we update the dropoff address.
    return {
      ...delivery,
      dropoffAddress: address,
    };
  }

  // It's a send request, so we update the pickup address.
  return {
    ...delivery,
    pickupAddress: address,
  };
};

/**
 * Updates a delivery object with the ex's address.
 * This returns a new delivery object, not a mutation.
 */
export const addExAddress = (delivery: Delivery, address: Address): Delivery => {
  if (delivery.type === DeliveryType.request) {
    // It's a retrieve request, so we update the pickup address.
    return {
      ...delivery,
      pickupAddress: address,
    };
  }

  // It's a send request, so we update the drop-off address.
  return {
    ...delivery,
    dropoffAddress: address,
  };
};

/**
 * Returns a new delivery with the relationship scenario provided.
 */
export const addScenario = (delivery: Delivery, relationship: Relationship): Delivery => ({
  ...delivery,
  relationshipId: relationship.id,
  relationship,
});

/**
 * Adds an item to a delivery's items. Returns a new delivery object.
 */
export const addItem = (delivery: Delivery, item: DeliveryItem): Delivery => {
  const updated = { ...delivery };
  if (!updated.items) {
    updated.items = [];
  }

  updated.items.push(item);
  return updated;
};

/**
 * Returns a new delivery with the provided items. Removes any existing items.
 */
export const addItems = (delivery: Delivery, items: DeliveryItem[]): Delivery => ({
  ...delivery,
  items,
});

/**
 * Adds the local information about the ex and initiator. Overrides any if it already exists.
 */
export const addExInfo = (delivery: Delivery, exName: string, exPhone: string, initiatorName: string): Delivery => ({
  ...delivery,
  ex: {
    name: exName,
    phone: exPhone,
  },
  initiator: {
    name: initiatorName,
  },
});

/**
 * Returns a new delivery with the chosen pickup time.
 * The time should be provided as an ISO string.
 */
export const addPickupTime = (delivery: Delivery, time: string): Delivery => ({
  ...delivery,
  pickupTime: time,
});

/**
 * Updates the initiator of a delivery and returns a new copy.
 */
export const updateInitiator = (delivery: Delivery, userId: string): Delivery => ({
  ...delivery,
  initiatorId: userId,
});

/**
 * Updates the status of the delivery and returns a new copy.
 */
export const updateStatus = (delivery: Delivery, status: DeliveryStatus): Delivery => ({
  ...delivery,
  status,
});

/**
 * Updates a delivery's dropoff address with a second address line and delivery instructions if present.
 */
export const updateDropoffInfo = (
  delivery: Delivery,
  address2: string | undefined,
  instructions: string | undefined
): Delivery => {
  const address = delivery.dropoffAddress;
  if (!address) {
    throw new Error(`Delivery has no dropoff address to update`);
  }

  address.lineTwo = address2;
  address.instructions = instructions;

  return {
    ...delivery,
    dropoffAddress: address,
  };
};

/**
 * Updates a delivery's pickup address with a second address line and delivery instructions if present.
 */
export const updatePickupInfo = (
  delivery: Delivery,
  address2: string | undefined,
  instructions: string | undefined
): Delivery => {
  const address = delivery.pickupAddress;
  if (!address) {
    throw new Error(`Delivery has no pickup address to update`);
  }

  address.lineTwo = address2;
  address.instructions = instructions;

  return {
    ...delivery,
    dropoffAddress: address,
  };
};

/**
 * DELIVERY ITEMS
 */

/**
 * Create a new delivery item.
 */
export const createDeliveryItem = (name: string, notes?: string, quantity = 1): DeliveryItem => ({
  id: uuidv4(),
  name,
  notes,
  quantity,
  available: false,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
});
