import nookies from 'nookies';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { isValidDelivery } from './delivery';
import { Delivery, DeliveryType, User } from './types';

export const AUTH_TOKEN_KEY = '_pd-auth';
const CURRENT_DELIVERY_KEY = '_pd-delivery';
const SEEN_ONBOARDING_KEY = '_pd-onb';

type AuthState = {
  user?: User;
  token: string | null;
  delivery: Delivery | null;
  deliveryType: DeliveryType;
  seenOnboarding: boolean;
  activeDeliveries: number;
  logout: () => void;
  updateUser: (user?: User) => void;
  updateAuth: (user?: User, token?: string) => void;
  clearDelivery: () => void;
  updateDelivery: (delivery?: Delivery) => void;
  updateType: (deliveryType: DeliveryType) => void;
  updateSeenOnboarding: (seen: boolean) => void;
  updateActiveDeliveries: (count: number) => void;
};

const AuthContext = createContext<AuthState>({
  user: undefined,
  token: null,
  delivery: null,
  deliveryType: DeliveryType.request,
  seenOnboarding: true,
  activeDeliveries: 0,

  logout: () => {
    console.warn('Implement me!');
  },
  updateUser: () => {
    console.warn('Implement me!');
  },
  updateAuth: () => {
    console.warn('Implement me!');
  },
  clearDelivery: () => {
    console.warn('Implement me!');
  },
  updateDelivery: () => {
    console.warn('Implement me!');
  },
  updateType: () => {
    console.warn('Implement me!');
  },
  updateSeenOnboarding: () => {
    console.warn('Implement me!');
  },
  updateActiveDeliveries: () => {
    console.warn('Implement me!');
  },
});

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | undefined>();
  const [token, setToken] = useState<string | undefined>(null);
  const [seenOnboarding, setSeenOnboarding] = useState(true);
  const [delivery, setDelivery] = useState<Delivery | null>(null);
  const [deliveryType, setDeliveryType] = useState<DeliveryType>(DeliveryType.request);
  const [activeDeliveries, setActiveDeliveries] = useState(0);

  const updateAuth = useCallback((user?: User, token?: string) => {
    setUser(user);
    setToken(token);

    if (token) {
      nookies.set(null, AUTH_TOKEN_KEY, token, {
        maxAge: 90 * 24 * 60 * 60,
        path: '/',
        encode: (value: any) => value,
      });
    } else {
      nookies.destroy(null, AUTH_TOKEN_KEY, { path: '/' });
    }
  }, []);

  const updateSeenOnboarding = useCallback((seen: boolean) => {
    setSeenOnboarding(seen);
    if (seen) {
      localStorage.setItem(SEEN_ONBOARDING_KEY, 'true');
    } else {
      localStorage.removeItem(SEEN_ONBOARDING_KEY);
    }
  }, []);

  const updateDelivery = useCallback((update) => {
    // console.info('Updated delivery:', update);
    if (update) {
      setDelivery(update);

      // Persist in local storage.
      localStorage.setItem(CURRENT_DELIVERY_KEY, JSON.stringify(update));
    } else {
      setDelivery(undefined);

      // Clear local storage.
      localStorage.removeItem(CURRENT_DELIVERY_KEY);
    }
  }, []);

  const clearDelivery = useCallback(() => {
    // Sets delivery to null so that security hook doesn't boot us.
    setDelivery(null);

    // Clear local storage.
    localStorage.removeItem(CURRENT_DELIVERY_KEY);
  }, []);

  const logout = useCallback(() => {
    updateAuth(undefined, undefined);
    updateDelivery(undefined);
  }, [updateAuth, updateDelivery]);

  // Effects for state recovery.

  useEffect(() => {
    // Attempt to retrieve a cached delivery from local storage.
    const cached = localStorage.getItem(CURRENT_DELIVERY_KEY);
    // console.info('Cached delivery', cached);
    if (cached) {
      const delivery: Delivery = JSON.parse(cached);
      if (isValidDelivery(delivery)) {
        updateDelivery(delivery);
      } else {
        console.warn('Cached delivery not valid');
        updateDelivery(undefined);
      }
    } else {
      updateDelivery(undefined);
    }
  }, [updateDelivery]);

  useEffect(() => {
    const isOnboarded = localStorage.getItem(SEEN_ONBOARDING_KEY);
    updateSeenOnboarding(!!isOnboarded);
  }, [updateSeenOnboarding]);

  useEffect(() => {
    // Attempt to retrieve token from cookie.
    const cookies = nookies.get();
    const token = cookies[AUTH_TOKEN_KEY];

    if (token) {
      setToken(token);
    } else {
      setToken(undefined);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        delivery,
        deliveryType,
        seenOnboarding,
        activeDeliveries,
        logout,
        updateUser: setUser,
        updateAuth,
        clearDelivery,
        updateDelivery,
        updateType: setDeliveryType,
        updateSeenOnboarding,
        updateActiveDeliveries: setActiveDeliveries,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthState => {
  return useContext(AuthContext);
};
