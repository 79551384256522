/**
 * Various types. Mirror the API defs.
 */

/**
 * Users can be on Postmates or not. We allow people to participate
 * in orders and do most things besides checkout without an account.
 */
export interface User {
  id: string;
  name: string;
  phone?: string;
  onPostdates: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface UserDetails {
  name?: string;
  phone?: string;
}

/** Address used for delivery or pickup. */
export interface Address {
  lineOne: string;
  lineTwo?: string;
  formatted: string;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
  placeId: string;
  instructions?: string;
}

/** How the relationship ended/what the breakup was. */
export interface Relationship {
  id: string;
  name: string;
  description: string;
  shortDescription: string;
  longDescription: string;
  timing: string;
  popular: boolean;
  imageUrl: string;
  coverImageUrl: string;
  badgeType: BadgeType;
  badgeColor: BadgeColor;
  rIdx: number;
  sIdx: number;
}

export enum DeliveryStatus {
  draft = 'DRAFT',
  contactingEx = 'CONTACTING_EX',
  awaitingConfirm = 'AWAITING_CONFIRM',
  scheduled = 'SCHEDULED',
  pickingUp = 'PICKING_UP',
  droppingOff = 'DROPPING_OFF',
  completed = 'COMPLETED',
  cancelled = 'CANCELLED',
}

export enum CancellationReason {
  initiator = 'INITIATOR_CANCELLED',
  courier = 'COURIER_CANCELLED',
  notInterested = 'EX_NOT_INTERESTED',
  noItems = 'EX_NO_ITEMS',
  notInRange = 'EX_NOT_IN_DELIVERY_RANGE',
  noTimes = 'EX_NO_ACCEPTABLE_TIMES',
  ignored = 'EX_NO_RESPONSE',
}

export interface DeliveryItem {
  id: string;
  name: string;
  description?: string;
  notes?: string;
  imageUrl?: string;
  coverImageUrl?: string;
  quantity: number;
  available: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum DeliveryType {
  request = 'REQUEST',
  send = 'SEND',
}

export interface Courier {
  name?: string;
  phone?: string;
}

export interface Delivery {
  // Mirror of DB schema.
  id: string;
  type: DeliveryType;
  status: DeliveryStatus;
  statusReason?: CancellationReason;
  shortId?: string;
  relationshipId?: string;
  initiatorId: string;
  exId?: string;
  pickupUserId?: string;
  pickupAddressId?: string;
  pickupTime?: string;
  pickupEta?: string;
  dropoffUserId?: string;
  dropoffAddressId?: string;
  dropoffTime?: string;
  dropoffEta?: string;
  subtotal?: number;
  deliveryFee?: number;
  service?: string;
  platformFee?: number;
  tip?: number;
  total?: number;
  paid?: boolean;
  createdAt: string;
  updatedAt: string;

  // Materialized fields.
  courier?: Courier;
  pickupAddress?: Address;
  pickupUser?: User;
  dropoffAddress?: Address;
  dropoffUser?: User;
  items?: DeliveryItem[];
  relationship?: Relationship;
  initiator?: UserDetails;
  ex?: UserDetails;
}

export interface HistoryOrder {
  delivery: Delivery;
  items: DeliveryItem[];
  pickupAddress: Address;
  pickupUser: User;
  dropoffAddress: Address;
  dropoffUser: User;
}

/**
 * API Payloads.
 */

export type PaymentSecretPayload = { delivery: Delivery; tip: number };

export type FinalizePaymentPayload = { deliveryId: string; intentId: string };

// It's expected that some of these fields have dummy values in order
// to not leak PII/private data to the other party.
export type DeliveryResponse = {
  courier?: Courier;
  delivery: Delivery;
  items: DeliveryItem[];
  pickupAddress: Address;
  dropoffAddress: Address;
  initiator: UserDetails;
  ex: UserDetails;

  // Current user if the request is authenticated.
  user?: User;
};

/**
 * UI Elements.
 */

export interface TipAmount {
  id: string;
  percent: string;
  amount: number;
}

export interface FeaturedCard {
  id: string;
  name: string;
  details: string;
  lightText: boolean;
  url: string;
  external: boolean;
  imageUrl: string;
}

export enum BadgeType {
  popular = 'popular', // ** purple
  groups = 'good for groups', // ** red
  new = 'new on postdates',
  only = 'only on postdates',
  deal = 'daily deal', // ** pink
  fast = 'get it fast', // ** red
  favorites = 'top rated',
  top = 'top rated',
}

export enum BadgeColor {
  red = 'red',
  pink = 'pink',
  purple = 'purple',
}
